import Vue from 'vue'

// axios
import axios from 'axios'

const axiosIns = axios.create({
  baseURL: 'https://hipole.com:88/api/',
  // timeout: 1000,
  // headers: { 'content-type:': 'application/json' },
  // headers: { 'Access-Control-Allow-Origin:': '*' },
})

Vue.prototype.$http = axiosIns

export default axiosIns
